import axios from "axios";
import { SERVER_BASE_URL } from "../../config/config";
import { ActionTypes } from "../constants/action-types";
import { toast } from "react-toastify";

export const setNewArrivals = (products) => {
  return {
    type: ActionTypes.SET_NEW_ARRIVALS,
    payload: products,
  };
};

export const setTrendingNow = (products) => {
  return {
    type: ActionTypes.SET_TRENDING_NOW,
    payload: products,
  };
};

export const setOldAuthorsChoice = (products) => {
  return {
    type: ActionTypes.SET_OLD_AUTHORS_CHOICE,
    payload: products,
  };
};

export const setDiscountedBooks = (products) => {
  return {
    type: ActionTypes.SET_DISCOUNTED_BOOKS,
    payload: products,
  };
};

export const setBestSellers = (products) => {
  return {
    type: ActionTypes.SET_BEST_SELLERS,
    payload: products,
  };
};

export const selectedProduct = (product) => {
  return {
    type: ActionTypes.SELECTED_PRODUCT,
    payload: product,
  };
};

export const getProducts =
  ({
    page,
    search = "",
    category = "",
    author = "",
    sort = "publishedYear,title",
  }) =>
  async (dispatch) => {
    const response = await axios.get(
      `${SERVER_BASE_URL}/v1/app/books?paginate=true&limit=8&page=${page}&search=${search}&category=${category}&author=${author}&sortBy=${sort}`
    );
    dispatch({
      type: ActionTypes.GET_PRODUCTS,
      payload: response.data,
    });
  };

export const getCombos =
  ({ page }) =>
  async (dispatch, getState) => {
    // const token = getState().userLogin.user.tokens.access.token;
    const response = await axios.get(
      `${SERVER_BASE_URL}/v1/app/combos?paginate=true&limit=8&page=${page}`
    );
    dispatch({
      type: ActionTypes.GET_COMBOS,
      payload: response.data,
    });
  };

export const setReview = (token, id, review, rating) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${SERVER_BASE_URL}/v1/app/add-review/${id}`,
      { message: review, rating: rating },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: ActionTypes.GET_AUTHORS,
      payload: response.data,
    });
  } catch (e) {
    toast.error(e);
  }
};

export const searchBooks = (search, token) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${SERVER_BASE_URL}/v1/app/books?paginate=true&search=${search}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: ActionTypes.SEARCH_PRODUCTS,
      payload: response.data.payload.results,
    });
  } catch (e) {
    toast.error(e);
  }
};
