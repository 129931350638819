import { ActionTypes } from "../constants/action-types";

const initialState = {};

export const translatorReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_TRANSLATOR:
      return { ...state, translator: { ...payload } };
    case ActionTypes.GET_TRANSLATORS:
      return { ...state, translators: { ...payload } };
    case ActionTypes.GET_SINGLE_TRANSLATOR:
      return { ...state, translator: { ...payload } };
    default:
      return state;
  }
};
