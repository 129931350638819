import React, { lazy, Component, useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCombos, getProducts } from "../../redux/actions/productActions";
import { ReactComponent as IconSearch } from "bootstrap-icons/icons/search.svg";
import {
  getCategories,
  getCategory,
} from "../../redux/actions/categoriesActions";
import { Link, useLocation } from "react-router-dom";
import { data } from "../../data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTh, faBars } from "@fortawesome/free-solid-svg-icons";
import { SERVER_BASE_URL } from "../../config/config";
import AppPagination from "../../helpers/appPagination";
import Search from "../../components/Search";

const Paging = lazy(() => import("../../components/Paging"));
const Breadcrumb = lazy(() => import("../../components/Breadcrumb"));
const FilterCategory = lazy(() => import("../../components/filter/Category"));
const FilterPrice = lazy(() => import("../../components/filter/Price"));
const FilterSize = lazy(() => import("../../components/filter/Size"));
const FilterStar = lazy(() => import("../../components/filter/Star"));
const FilterColor = lazy(() => import("../../components/filter/Color"));
const FilterTag = lazy(() => import("../../components/filter/Tag"));
const FilterClear = lazy(() => import("../../components/filter/Clear"));
const CardServices = lazy(() => import("../../components/card/CardServices"));
const CardProductGrid = lazy(() =>
  import("../../components/card/CardProductGrid")
);
const CardProductList = lazy(() =>
  import("../../components/card/CardProductList")
);

const ComboList = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [view, setView] = useState("grid");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const products = useSelector((state) => state?.product?.combos?.payload);
  const authTokens = useSelector((state) => state?.userLogin?.user?.tokens);

  useEffect(() => {
    dispatch(getCombos({ page, search: search }));
  }, [page, search]);

  console.log("combo", products);

  return (
    <React.Fragment>
      <div
        className="p-5 bg-primary bs-cover"
        style={
          {
            // backgroundImage: "url(../../images/banner/50-Banner.webp)",
          }
        }
      >
        <div className="container text-center">
          <span className="display-5 px-3 text-light">Combo Offers</span>
        </div>
      </div>
      {/* <Breadcrumb /> */}
      <div className="container-fluid mt-3 mb-3">
        <div className="row">
          <div className="col-md-12">
            <hr />
            <div className="row g-3">
              {products &&
                products?.map((product, idx) => {
                  return (
                    <div key={idx} className="col-md-3 mb-5">
                      <CardProductGrid
                        imageHeight={200}
                        imageWidth={"150px"}
                        data={product}
                        type={"combos"}
                      />
                    </div>
                  );
                })}
            </div>
            <hr />
            <AppPagination setPage={setPage} {...products} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ComboList;
