import React from "react";
import ReactPaginate from "react-paginate";

const AppPagination = ({ page, totalPages, setPage }) => {
  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  return (
    // <>
    //   {totalPages > 0 ? (
    //     <div class="pagination">
    //       {page > 1 && (
    //         <button class="btn" onClick={() => setPage(page - 1)}>
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             class="btn--icon"
    //             fill="none"
    //             viewBox="0 0 24 24"
    //             stroke="currentColor"
    //             stroke-width="2"
    //           >
    //             <path
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //               d="M15 19l-7-7 7-7"
    //             />
    //           </svg>
    //         </button>
    //       )}
    //       <div class="pages">
    //         {Array(+totalPages)
    //           .fill()
    //           .map((p, i) => (
    //             <a
    //               onClick={() => setPage(i + 1)}
    //               className={`page ${i + 1 === page ? "pageactive" : ""}`}
    //             >
    //               {i + 1}
    //             </a>
    //           ))}
    //       </div>
    //       {page === totalPages && (
    //         <button class="pagebtn" onClick={() => setPage(page + 1)}>
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             class="btn--icon"
    //             fill="none"
    //             viewBox="0 0 24 24"
    //             stroke="currentColor"
    //             stroke-width="2"
    //           >
    //             <path
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //               d="M9 5l7 7-7 7"
    //             />
    //           </svg>
    //         </button>
    //       )}
    //     </div>
    //   ) : null}
    // </>
    <>
      <ReactPaginate
        breakLabel="..."
        nextLabel="Next >"
        onPageChange={handlePageClick}
        containerClassName="pagination justify-content-center"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        activeClassName="active"
        pageRangeDisplayed={5}
        pageCount={totalPages}
        previousLabel="< Previous"
        renderOnZeroPageCount={null}
      />
    </>
  );
};

export default AppPagination;
