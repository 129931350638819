import { ActionTypes } from "../constants/action-types";

const initialState = {};

export const bannerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_BANNER:
      return { ...state, banner: { ...payload } };
    default:
      return state;
  }
};
