import React from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import renderFormGroupField from "../../helpers/renderFormGroupField";
import { required, maxLength20, minLength8 } from "../../helpers/validation";
import { ReactComponent as IconShieldLockFill } from "bootstrap-icons/icons/shield-lock-fill.svg";
import { ReactComponent as IconKeyFill } from "bootstrap-icons/icons/key-fill.svg";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { changePassword, resetPassword } from "../../redux/actions/authActions";
import { toast } from "react-toastify";

const ChangePasswordForm = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");

  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");
  console.log(token);

  const authTokens = useSelector((state) => state?.userLogin?.user?.tokens);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    try {
      if (password !== retypePassword) {
        toast.error("Password Mismatch!");
      } else {
        if (token) {
          await dispatch(changePassword(token, password));
        } else {
          await dispatch(resetPassword(authTokens?.access?.token, password));
        }
        history.push("/");
      }
    } catch (e) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <div className="card border-info">
      <h6 className="card-header bg-danger text-white">
        <IconKeyFill /> Change Password
      </h6>
      <div className="card-body">
        <form onSubmit={handleSubmit} noValidate>
          <div className="row">
            <div className="col-md-12">
              <label>New Password</label>
            </div>
            <div className="col-md-12">
              <input
                name="password"
                type="password"
                label="New Password"
                placeholder="******"
                required={true}
                onChange={(e) => setPassword(e.target.value)}
                className="mb-3 mt-2"
              />
            </div>{" "}
            <div className="col-md-12">
              <label>Re-Type New Password</label>
            </div>
            <div className="col-md-12">
              <input
                name="retypePassword"
                type="password"
                label="Re-Type Password"
                placeholder="******"
                required={true}
                onChange={(e) => setRetypePassword(e.target.value)}
                className="mb-3 mt-2"
              />
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-danger btn-block"
            // disabled={submitting}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default compose(
  reduxForm({
    form: "changepassword",
  })
)(ChangePasswordForm);
