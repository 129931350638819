import React, { useState, Component, lazy, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { data } from "../../data";
import { getWishList } from "../../redux/actions/wishlistActions";
import { useHistory } from "react-router-dom";
const CardProductList2 = lazy(() =>
  import("../../components/card/CardProductList2")
);

const RefundCancellations = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className=" mb-3 bs-cover">
      <div className="p-5 bg-primary bs-cover mb-5">
        <div className="container text-center">
          <span className="display-5 px-3 text-light">
            Refund & Cancellations
          </span>
        </div>
      </div>
      <div className="container g-3 mb-5">
        <p>
          <span>
            Once you place the order &amp; paid the amount, you cannot cancel
            the order.
          </span>
        </p>

        <p>
          Once you place the order &amp; paid the amount, we will not refund the
          amount.
        </p>
      </div>
    </div>
  );
};

export default RefundCancellations;
