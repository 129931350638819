import { ActionTypes } from "../constants/action-types";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SERVER_BASE_URL } from "../../config/config";

export const addToWishList = (id, token) => async (dispatch, getState) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const response = await axios.post(
    `${SERVER_BASE_URL}/v1/app/add-wishlist`,
    { item: id },
    config
  );

  const getWishlist = await axios.get(`${SERVER_BASE_URL}/v1/app/wishlist`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  dispatch({
    type: ActionTypes.GET_WISHLIST,
    payload: getWishlist.data,
  });
};

export const getWishList = (token) => async (dispatch) => {
  try {
    const response = await axios.get(`${SERVER_BASE_URL}/v1/app/wishlist`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: ActionTypes.GET_WISHLIST,
      payload: response.data,
    });
  } catch (e) {
    toast.error(e);
  }
};

export const removeItemWishlist = (token, id) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${SERVER_BASE_URL}/v1/app/remove-wishlist`,
      { item: id },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    dispatch({
      type: ActionTypes.REMOVE_ITEM_WISHLIST,
      payload: response.data.payload,
    });
  } catch (e) {
    toast.error(e);
  }
};
