import { ActionTypes } from "../constants/action-types";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SERVER_BASE_URL } from "../../config/config";

export const setAuthor = (author) => {
  return {
    type: ActionTypes.SET_AUTHOR,
    payload: author,
  };
};

export const getAuthors = (page, boolean) => async (dispatch) => {
  try {
    let response;
    if (boolean) {
      response = await axios.get(
        `${SERVER_BASE_URL}/v1/app/authors?limit=9&search=&paginate=${boolean}&page=${page}&sortBy=popularity:asc,name`,
        {}
      );
      dispatch({
        type: ActionTypes.GET_AUTHORS,
        payload: response.data,
      });
    } else {
      response = await axios.get(
        `${SERVER_BASE_URL}/v1/app/authors?search=`,
        {}
      );
      dispatch({
        type: ActionTypes.GET_LIST_AUTHORS,
        payload: response.data,
      });
    }
  } catch (e) {
    toast.error(e);
  }
};

export const getSingleAuthor = (id, page) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${SERVER_BASE_URL}/v1/app/author/${id}?limit=9&paginate=true&page=${page}`
    );
    dispatch({
      type: ActionTypes.GET_SINGLE_AUTHOR,
      payload: response.data,
    });
  } catch (e) {
    toast.error(e);
  }
};
