import { ActionTypes } from "../constants/action-types";

const initialState = {};

export const myOrdersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_MY_ORDERS:
      return { ...state, orders: { ...payload } };
    default:
      return state;
  }
};
