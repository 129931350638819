import { ActionTypes } from "../constants/action-types";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SERVER_BASE_URL } from "../../config/config";

export const setTranslator = (author) => {
  return {
    type: ActionTypes.SET_TRANSLATOR,
    payload: author,
  };
};

export const getTranslators = (page) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${SERVER_BASE_URL}/v1/app/translators?search=&page=${page}&paginate=true&limit=9&sortBy=name:asc`,
      {}
    );
    dispatch({
      type: ActionTypes.GET_TRANSLATORS,
      payload: response.data,
    });
  } catch (e) {
    toast.error(e);
  }
};

export const getSingleTranslator = (id) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${SERVER_BASE_URL}/v1/app/translator/${id}`
    );
    dispatch({
      type: ActionTypes.GET_SINGLE_TRANSLATOR,
      payload: response.data,
    });
  } catch (e) {
    toast.error(e);
  }
};
