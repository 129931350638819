import React, { lazy, Component, useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCategories } from "../redux/actions/categoriesActions";
import { Link } from "react-router-dom";
import { SERVER_BASE_URL } from "../config/config";
import { getAuthors } from "../redux/actions/authorActions";
import { useHistory } from "react-router-dom";

const TopMenu = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const allCategories = useSelector(
    (state) => state?.allCategories?.categories
  );

  const allAuthors = useSelector(
    (state) => state?.allAuthors?.authors?.payload
  );
  console.log("allcsadsadsad", allCategories);

  console.log("allAuthors", allAuthors);

  useEffect(() => {
    Promise.all([
      (async () => {
        dispatch(getCategories());
      })(),
      (async () => {
        dispatch(getAuthors());
      })(),
    ]).then((values) => {
      console.log(values);
    });
  }, []);

  console.log(allCategories);
  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-lg navbar-light bg-light p-0">
        <div className="container-fluid">
          {/* <Link className="navbar-brand" to="/">
            E-Commerce
          </Link> */}
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/">
                  <img
                    src="../../images/icons/home.png"
                    width="40"
                    alt="home"
                    className="nav-link"
                  />
                </Link>
              </li>

              {/* <li className="nav-item">
                <Link className="nav-link" to="/">
                  அகராதி
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  சிறுகதைகள்
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  நாவல்
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  கவிதைகள்
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  கட்டுரைகள்
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  மொழி பெயர்ப்பு
                </Link>
              </li>*/}
              <li className="nav-item">
                <div
                  style={{ cursor: "pointer" }}
                  className="nav-link"
                  onClick={() =>
                    history.push({
                      pathname: "/shop",
                      state: { categoryId: "62bc9fcbfa81f22299861773" },
                    })
                  }
                >
                  நாவல்கள்
                </div>
              </li>
              <li className="nav-item">
                <div
                  style={{ cursor: "pointer" }}
                  className="nav-link"
                  onClick={() =>
                    history.push({
                      pathname: "/shop",
                      state: { categoryId: "62bc9fcbfa81f22299861776" },
                    })
                  }
                >
                  கவிதை நூல்கள்
                </div>
              </li>
              <li className="nav-item">
                <div
                  style={{ cursor: "pointer" }}
                  className="nav-link"
                  onClick={() =>
                    history.push({
                      pathname: "/shop",
                      state: { categoryId: "62bc9fcbfa81f22299861775" },
                    })
                  }
                >
                  கட்டுரைகள்
                </div>
              </li>
              <li className="nav-item">
                <div
                  style={{ cursor: "pointer" }}
                  className="nav-link"
                  onClick={() =>
                    history.push({
                      pathname: "/shop",
                      state: { categoryId: "62bc9fcbfa81f22299861779" },
                    })
                  }
                >
                  மொழிபெயர்ப்புகள்
                </div>
              </li>
              <li className="nav-item">
                <div
                  style={{ cursor: "pointer" }}
                  className="nav-link"
                  onClick={() =>
                    history.push({
                      pathname: "/shop",
                      state: { categoryId: "62bec67d00426363f6513e6a" },
                    })
                  }
                >
                  நகரங்களின் கதைகள்
                </div>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/shop">
                  Shop
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/authors">
                  Authors
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/translators">
                  Translators
                </Link>
              </li>

              <li className="nav-item dropdown">
                <button
                  className="btn nav-link dropdown-toggle font-weight-bold"
                  id="navbarDropdown"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="navbar-toggler-icon" /> Categories
                </button>
                <ul
                  style={{ maxHeight: "350px", overflowY: "scroll" }}
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown"
                >
                  {allCategories &&
                    allCategories?.payload?.map((d) => (
                      <li
                        onClick={() =>
                          history.push({
                            pathname: "/shop",
                            state: { categoryId: d?.id },
                          })
                        }
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          className="dropdown-item"
                        >
                          {d?.name}
                        </div>
                      </li>
                    ))}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default TopMenu;
