import { ActionTypes } from "../constants/action-types";

const initialState = {};

export const productReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_NEW_ARRIVALS:
      return { ...state, newArrivals: { ...payload } };
    case ActionTypes.SET_TRENDING_NOW:
      return { ...state, trendingNow: { ...payload } };
    case ActionTypes.SET_BEST_SELLERS:
      return { ...state, bestSellers: { ...payload } };
    case ActionTypes.SET_OLD_AUTHORS_CHOICE:
      return { ...state, oldAuthorsChoice: { ...payload } };
    case ActionTypes.SET_DISCOUNTED_BOOKS:
      return { ...state, discountedBooks: { ...payload } };
    case ActionTypes.GET_PRODUCTS:
      return { ...state, products: { ...payload } };
    case ActionTypes.GET_COMBOS:
      return { ...state, combos: { ...payload } };
    case ActionTypes.SELECTED_PRODUCT:
      return { ...state, product: { ...payload } };
    case ActionTypes.SET_REVIEW:
      return { ...state, productReview: { ...payload } };
    case ActionTypes.SEARCH_PRODUCTS:
      return { ...state, searchProducts: [...payload] };
    default:
      return state;
  }
};
