import React, { lazy, Component, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Search from "./Search";
import { ReactComponent as IconCart3 } from "bootstrap-icons/icons/cart3.svg";
import { ReactComponent as IconPersonBadgeFill } from "bootstrap-icons/icons/person-badge-fill.svg";
import { ReactComponent as IconStarFill } from "bootstrap-icons/icons/star-fill.svg";
import { ReactComponent as IconListCheck } from "bootstrap-icons/icons/list-check.svg";
import { ReactComponent as IconDoorClosedFill } from "bootstrap-icons/icons/door-closed-fill.svg";
import { ReactComponent as IconHeartFill } from "bootstrap-icons/icons/heart-fill.svg";
import { ReactComponent as IconBellFill } from "bootstrap-icons/icons/bell-fill.svg";
import { ReactComponent as IconInfoCircleFill } from "bootstrap-icons/icons/info-circle-fill.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faUser } from "@fortawesome/free-solid-svg-icons";
import { logoutUser } from "../redux/actions/authActions";
import { SERVER_BASE_URL } from "../config/config";
import { getCart } from "../redux/actions/cartActions";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { searchBooks } from "../redux/actions/productActions";
import { ActionTypes } from "../redux/constants/action-types";
import { getWishList } from "../redux/actions/wishlistActions";

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cartCount, setCartCount] = useState("");
  const [focus, setFocus] = useState(false);

  const authTokens = useSelector((state) => state?.userLogin?.user?.tokens);
  const profile = useSelector((state) => state?.userLogin?.user?.user);
  const cartItems = useSelector((state) => state?.getCart?.cart);
  const searchProducts = useSelector((state) => state?.product?.searchProducts);
  const wishlistItems = useSelector(
    (state) => state?.allWishlist?.wishlistItems?.payload
  );

  const accessToken = authTokens?.access?.token;
  const refreshToken = authTokens?.refresh?.token;
  console.log("ddddddddddddddddddddddddddddddddddddd", searchProducts);
  console.log(authTokens);
  console.log(profile);

  useEffect(() => {
    if (authTokens) {
      (async () => {
        dispatch(getCart(authTokens.access.token));
      })();
    }
  }, [authTokens]);

  useEffect(() => {
    if (authTokens) {
      (async () => {
        dispatch(getWishList(authTokens.access.token));
      })();
    }
  }, [authTokens]);

  useEffect(() => {
    if (cartItems?.items) {
      setCartCount(cartItems?.items?.length);
    }
  }, [cartItems]);

  const logout = async (evt) => {
    evt.preventDefault(evt.target.value);
    const response = await axios
      .post(`${SERVER_BASE_URL}/v1/auth/logout`, { refreshToken })
      .catch((err) => {
        console.log("Err", err);
      });

    console.log(response);
    dispatch(logoutUser(response));
    history.push("/account/signin");
  };

  const onCartClick = () => {
    if (!cartItems?.items?.length) {
      toast.error("Your Cart is Empty!");
    }
  };

  const onWishListClick = () => {
    if (!wishlistItems?.length) {
      toast.error("Your Wishlist is Empty!");
    }
  };

  const handleSearchChange = (e) => {
    dispatch(searchBooks(e.target.value, accessToken));
  };

  const handleFocus = (e) => {
    dispatch(searchBooks(e.target.value, accessToken));
    setFocus(true);
  };

  return (
    <React.Fragment>
      <header className="p-3 border-bottom">
        <div className="container-fluid">
          <div className="row g-3" style={{ alignItems: "center" }}>
            <div className="col-md-4 mb-4">
              <Link to="/">
                <img
                  width={"250"}
                  height={"55"}
                  alt="logo"
                  src="../../images/logo-sandhiya.png"
                />
              </Link>
            </div>
            {focus && (
              <div className="overlay" onClick={() => setFocus(false)}></div>
            )}
            <div className="col-md-4 mb-4" style={{ position: "relative" }}>
              <Search
                onChange={handleSearchChange}
                onFocus={(e) => handleFocus(e)}
              />
              {focus && searchProducts && (
                <div className="search_drop">
                  {searchProducts?.map((product, i) => (
                    <>
                      <div
                        className="search_items"
                        onClick={() => {
                          setFocus(false);
                          history.push(`/book/${product.id}`);
                        }}
                      >
                        {product?.title}
                      </div>
                      {i !== searchProducts.length - 1 && (
                        <hr className="mb-2 mt-2" />
                      )}
                    </>
                  ))}
                </div>
              )}
            </div>
            <div className="col-md-4 mb-4" style={{ textAlignLast: "end" }}>
              <div
                onClick={onCartClick}
                className="position-relative d-inline mr-3"
              >
                <Link
                  to={cartItems?.items?.length && "/cart"}
                  className="btn btn-danger"
                >
                  <IconCart3 className="i-va" />
                  <div className="position-absolute top-0 left-100 translate-middle badge bg-dark rounded-circle">
                    {cartItems?.items?.length}
                  </div>
                </Link>
              </div>

              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-danger  border mr-3 dropdown-toggle1"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  aria-label="Profile"
                  data-bs-toggle="dropdown"
                >
                  <FontAwesomeIcon icon={faUser} className="text-light" />
                  {"  "}
                  {authTokens && authTokens?.refresh?.token
                    ? profile?.name
                    : "Log In/ Register"}
                </button>
                <ul className="dropdown-menu">
                  {authTokens && authTokens?.refresh?.token ? (
                    <>
                      <li>
                        <Link className="dropdown-item" to="/account/my-orders">
                          <IconPersonBadgeFill /> My Orders
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/account/change-password"
                        >
                          <IconPersonBadgeFill /> Change Password
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={onWishListClick}
                          to={wishlistItems?.length && "/account/wishlist"}
                          className="dropdown-item"
                        >
                          <IconHeartFill className="text-danger" /> Wishlist
                        </Link>
                      </li>
                      <li>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={logout}
                          className="dropdown-item"
                        >
                          <IconDoorClosedFill className="text-danger" /> Logout
                        </div>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <Link className="dropdown-item" to="/account/signin">
                          <IconPersonBadgeFill /> Login
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/account/signup">
                          <IconPersonBadgeFill /> Register
                        </Link>
                      </li>
                    </>
                  )}
                  {/* <li>
                    <Link className="dropdown-item" to="/star/zone">
                      <IconStarFill className="text-warning" /> Star Zone
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/account/orders">
                      <IconListCheck className="text-primary" /> Orders
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link className="dropdown-item" to="/account/wishlist">
                      <IconHeartFill className="text-danger" /> Wishlist
                    </Link>
                  </li> */}
                  {/* <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/account/notification">
                      <IconBellFill className="text-primary" /> Notification
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/support">
                      <IconInfoCircleFill className="text-success" /> Support
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li> */}
                </ul>
              </div>

              <div className="position-relative d-inline ">
                <a
                  href="https://m.facebook.com/sandhyapublications/"
                  className="mr-2"
                >
                  <img
                    src="../../images/icons/facebook.png"
                    width="20"
                    alt="Visa"
                    className=""
                  />
                </a>
              </div>
              <div className="position-relative d-inline ">
                <a
                  href="https://twitter.com/sandhyapub?s=11&t=V0hWCpbEZkQrdEP-K072nA"
                  className="mr-2"
                >
                  <img
                    src="../../images/icons/twitter.png"
                    width="20"
                    alt="Visa"
                    className=""
                  />
                </a>
              </div>
              <div className="position-relative d-inline ">
                <a
                  href="https://instagram.com/sandhyapublications?igshid=NDBlY2NjN2I="
                  className="mr-2"
                >
                  <img
                    src="../../images/icons/instagram.png"
                    width="20"
                    alt="Visa"
                    className=""
                  />
                </a>
              </div>

              {/* <Link to="/account/signin">Sign In</Link> |{" "}
              <Link to="/account/signup"> Sign Up</Link> */}
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};
export default Header;
