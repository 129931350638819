import React, { Suspense, lazy, useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import TopMenu from "./components/TopMenu";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.min.css";
import "./App.css";

import AuthorList from "./views/authors/AuthorList";
import SingleAuthor from "./views/authors/SingleAuthor";
import YoutubeLinks from "./views/youtube/YoutubeLinks";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ChangePasswordForm from "./components/account/ChangePasswordForm";
import About from "./views/pages/About";
import DeliveryPolicy from "./views/pages/DeliveryPolicy";
import PrivacyPolicy from "./views/pages/PrivacyPolicy";
import TermsConditions from "./views/pages/TermsConditions";
import RefundCancellations from "./views/pages/RefundCancellations";
import Catalogue from "./views/pages/Catalogue";
import MyOrders from "./views/account/MyOrders";
import TranslatorList from "./views/translators/TranslatorList";
import SingleTranslator from "./views/translators/singleTranslator";
import ComboList from "./views/combo/ComboList";
import SuccessPage from "./views/pages/SuccessPage";

//const Header = lazy(() => import("./components/Header"));
//const TopMenu = lazy(() => import("./components/TopMenu"));
const HomeView = lazy(() => import("./views/Home"));
const SignInView = lazy(() => import("./views/account/SignIn"));
const SignUpView = lazy(() => import("./views/account/SignUp"));
const ForgotPasswordView = lazy(() => import("./views/account/ForgotPassword"));
const OrdersView = lazy(() => import("./views/account/Orders"));
const WishlistView = lazy(() => import("./views/account/Wishlist"));
const NotificationView = lazy(() => import("./views/account/Notification"));
const MyProfileView = lazy(() => import("./views/account/MyProfile"));
const ProductListView = lazy(() => import("./views/product/List"));
const ProductDetailView = lazy(() => import("./views/product/Detail"));
// const StarZoneView = lazy(() => import("./views/product/StarZone"));
const CartView = lazy(() => import("./views/cart/Cart"));
const CheckoutView = lazy(() => import("./views/cart/Checkout"));
const DocumentationView = lazy(() => import("./views/Documentation"));
const NotFoundView = lazy(() => import("./views/pages/404"));
const InternalServerErrorView = lazy(() => import("./views/pages/500"));
const ContactUsView = lazy(() => import("./views/pages/ContactUs"));
const SupportView = lazy(() => import("./views/pages/Support"));
const BlogView = lazy(() => import("./views/blog/Blog"));
const BlogDetailView = lazy(() => import("./views/blog/Detail"));

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      {loading ? (
        <div class="page-preloader">
          <img
            class="preloader-logo"
            src="../../images/logo-sandhiya.png"
            width="300"
          />
          <div class="preloader-preview-area">
            <div class="ball-pulse">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      ) : (
        <BrowserRouter>
          <React.Fragment>
            <Header />
            <TopMenu />
            <Suspense
              fallback={
                <div className="text-white text-center mt-3">Loading...</div>
              }
            >
              <Switch>
                <Route exact path="/account/signin" component={SignInView} />
                <Route exact path="/account/signup" component={SignUpView} />
                <Route exact path="/account/my-orders" component={MyOrders} />
                <Route
                  exact
                  path="/account/forgotpassword"
                  component={ForgotPasswordView}
                />
                <Route
                  exact
                  path="/account/change-password"
                  component={MyProfileView}
                />

                <Route
                  exact
                  path="/account/change-password"
                  component={ChangePasswordForm}
                />
                <Route exact path="/account/orders" component={OrdersView} />
                <Route exact path="/orders/success" component={SuccessPage} />
                <Route
                  exact
                  path="/account/wishlist"
                  component={WishlistView}
                />
                <Route
                  exact
                  path="/account/notification"
                  component={NotificationView}
                />
                <Route exact path="/shop" component={ProductListView} />
                <Route exact path="/combos" component={ComboList} />
                <Route exact path="/authors" component={AuthorList} />
                <Route exact path="/translators" component={TranslatorList} />
                <Route exact path="/author/:id" component={SingleAuthor} />
                <Route
                  exact
                  path="/translator/:id"
                  component={SingleTranslator}
                />
                <Route exact path="/book/:id" component={ProductDetailView} />
                <Route exact path="/cart" component={CartView} />
                <Route exact path="/checkout" component={CheckoutView} />
                <Route exact path="/youtube-links" component={YoutubeLinks} />
                <Route exact path="/contact-us" component={ContactUsView} />
                <Route exact path="/support" component={SupportView} />
                <Route exact path="/about" component={About} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route exact path="/catalogue" component={Catalogue} />
                <Route
                  exact
                  path="/refund-and-cancellations"
                  component={RefundCancellations}
                />
                <Route
                  exact
                  path="/terms-and-conditions"
                  component={TermsConditions}
                />
                <Route
                  exact
                  path="/delivery-shipping-policy"
                  component={DeliveryPolicy}
                />
                <Route exact path="/blog" component={BlogView} />
                <Route exact path="/blog/detail" component={BlogDetailView} />
                <Route exact path="/500" component={InternalServerErrorView} />
                <Route exact path="/" component={HomeView} />
                <Route component={NotFoundView} />
              </Switch>
            </Suspense>
            <Footer />
          </React.Fragment>
        </BrowserRouter>
      )}

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
