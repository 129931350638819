import { ActionTypes } from "../constants/action-types";

const initialState = {};

export const cartReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.ADD_TO_CART:
      return { ...state, cart: { items: [...payload] } };
    case ActionTypes.CLEAR_CART:
      return { ...state, cart: null };
    case ActionTypes.GET_CART:
      return { ...state, cart: { ...payload } };
    case ActionTypes.REMOVE_ITEM_CART:
      return { ...state, cartItemRemoved: { ...payload } };
    case ActionTypes.REMOVE_SINGLE_ITEM_CART:
      return { ...state, cart: { ...payload } };
    case "SAVE_CURR_CART":
      return { ...state, currCart: { ...payload } };
    default:
      return state;
  }
};
