import { ActionTypes } from "../constants/action-types";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SERVER_BASE_URL } from "../../config/config";

export const getMyOrders = (token) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${SERVER_BASE_URL}/v1/app/orders?paginate=true&sortBy=purchasedOn:desc`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: ActionTypes.GET_MY_ORDERS,
      payload: response.data,
    });
  } catch (e) {
    toast.error(e);
  }
};
