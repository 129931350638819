import React, { lazy, Component, useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getProducts } from "../../redux/actions/productActions";
import { getCategories } from "../../redux/actions/categoriesActions";
import { Link } from "react-router-dom";
import { data } from "../../data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTh, faBars } from "@fortawesome/free-solid-svg-icons";
import { SERVER_BASE_URL } from "../../config/config";
import { getAuthors } from "../../redux/actions/authorActions";
import { getTranslators } from "../../redux/actions/translatorActions";
import AppPagination from "../../helpers/appPagination";
import { Helmet } from "react-helmet-async";
const Paging = lazy(() => import("../../components/Paging"));
const Breadcrumb = lazy(() => import("../../components/Breadcrumb"));
const FilterCategory = lazy(() => import("../../components/filter/Category"));
const FilterPrice = lazy(() => import("../../components/filter/Price"));
const FilterSize = lazy(() => import("../../components/filter/Size"));
const FilterStar = lazy(() => import("../../components/filter/Star"));
const FilterColor = lazy(() => import("../../components/filter/Color"));
const FilterTag = lazy(() => import("../../components/filter/Tag"));
const FilterClear = lazy(() => import("../../components/filter/Clear"));
const CardServices = lazy(() => import("../../components/card/CardServices"));
const CardProductGrid = lazy(() =>
  import("../../components/card/CardProductGrid")
);
const CardProductList = lazy(() =>
  import("../../components/card/CardProductList")
);

const TranslatorList = () => {
  const dispatch = useDispatch();

  const [view, setView] = useState("grid");
  const [page, setPage] = useState(1);

  const products = useSelector((state) => state?.product?.products?.payload);

  const allCategories = useSelector(
    (state) => state?.allCategories?.categories?.payload?.results
  );

  const allAuthors = useSelector(
    (state) => state?.allTranslators?.translators?.payload
  );

  console.log(products);

  const getAllProducts = async () => {
    const response = await axios
      .get(`${SERVER_BASE_URL}/v1/app/books?search=`)
      .catch((err) => {
        console.log("Err", err);
      });

    console.log(response.data);
    dispatch(getProducts(response.data));
  };

  console.log(allCategories);

  useEffect(() => {
    Promise.all([
      (async () => {
        dispatch(getTranslators(page));
      })(),
    ]).then((values) => {
      console.log(values);
    });
  }, [page]);

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {`மொழிபெயர்ப்பாளர்கள் | Translators |
            சந்தியா பதிப்பகம் |
            Sandhyapathippagam |
            Sandhyapublications`}
        </title>
        <link
          rel="canonical"
          href={`https://www.sandhyapublications.com/translators`}
        />
      </Helmet>
      <div
        className="p-5 bg-primary bs-cover"
        style={
          {
            // backgroundImage: "url(../../images/banner/50-Banner.webp)",
          }
        }
      >
        <div className="container text-center">
          <span className="display-5 px-3 text-light">Translators</span>
        </div>
      </div>
      {/* <Breadcrumb /> */}
      <div className="container-fluid mt-3 mb-3">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-8">
                <span className="align-middle font-weight-bold">
                  {/* {this.state.totalItems} results for{" "} */}
                  <span className="text-warning"></span>
                </span>
              </div>
            </div>
            <hr />
            <div className="row g-3 ">
              {allAuthors &&
                allAuthors?.results?.map((author, idx) => {
                  return (
                    <div key={idx} className="col-md-4 mb-5">
                      <CardProductGrid data={author} type={"translators"} />
                    </div>
                  );
                })}
            </div>
            <hr />
            <AppPagination setPage={setPage} {...allAuthors} />
            {/* <Paging
                totalRecords={this.state.totalItems}
                pageLimit={9}
                pageNeighbours={3}
                onPageChanged={this.onPageChanged}
                sizing=""
                alignment="justify-content-center"
              /> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TranslatorList;
