import axios from "axios";
import { SERVER_BASE_URL } from "../../config/config";
import { ActionTypes } from "../constants/action-types";
import { ToastContainer, toast } from "react-toastify";

export const setUser = (user) => {
  return {
    type: ActionTypes.SET_USER,
    payload: user,
  };
};

export const guestLogin = () => async (dispatch) => {
  const response = await axios
    .post(`${SERVER_BASE_URL}/v1/auth/login`, {
      email: "guest@gmail.com",
      password: "Password1",
    })
    .catch((err) => {
      toast.error("Incorrect Email or Password!");
      console.log("Err", err);
    });
  return response?.data?.tokens?.access?.token;
};

export const addUser = (user) => {
  return {
    type: ActionTypes.ADD_USER,
    payload: user,
  };
};

export const logoutUser = (user) => {
  return {
    type: ActionTypes.LOGOUT_USER,
    payload: user,
  };
};

export const resetPassword = (token, password) => async (dispatch) => {
  const response = await axios.post(
    `${SERVER_BASE_URL}/v1/auth/reset-password`,
    { password: password },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  dispatch({
    type: ActionTypes.RESET_PASSWORD,
    payload: response.data.payload,
  });
};

export const changePassword = (token, password) => async (dispatch) => {
  const response = await axios.post(
    `${SERVER_BASE_URL}/v1/auth/change-password?token=${token}`,
    { password: password }
  );
  dispatch({
    type: ActionTypes.RESET_PASSWORD,
    payload: response.data.payload,
  });
};

export const forgotPassword = (user) => {
  return {
    type: ActionTypes.FORGOT_PASSWORD,
    payload: user,
  };
};

export const getAddress = (token) => async (dispatch) => {
  const response = await axios.get(`${SERVER_BASE_URL}/v1/users/address`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  dispatch({
    type: ActionTypes.SAVE_ADDRESS,
    payload: response.data.payload,
  });
};

export const deleteUserAddress = (id, token) => async (dispatch) => {
  const response = await axios.delete(
    `${SERVER_BASE_URL}/v1/users/address/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  dispatch({
    type: ActionTypes.SAVE_ADDRESS,
    payload: response.data.payload,
  });
};
