import { ActionTypes } from "../constants/action-types";

const initialState = {};

export const categoriesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_CATEGORIES:
      return { ...state, categories: { ...payload } };
    case ActionTypes.GET_CATEGORY:
      return { ...state, category: { ...payload } };
    case ActionTypes.REMOVE_ITEM_WISHLIST:
      return { ...state, wishListItemRemoved: { ...payload } };
    case "CLEAR_CATEGORY_BOOKS":
      return { ...state, category: null };
    default:
      return state;
  }
};
