import React, { useState, Component, lazy, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { data } from "../../data";
import { getWishList } from "../../redux/actions/wishlistActions";
import { useHistory } from "react-router-dom";
const CardProductList2 = lazy(() =>
  import("../../components/card/CardProductList2")
);

const TermsConditions = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className=" mb-3 bs-cover">
      <div className="p-5 bg-primary bs-cover mb-5">
        <div className="container text-center">
          <span className="display-5 px-3 text-light">Terms & Conditions</span>
        </div>
      </div>
      <div className="container g-3 mb-5">
        <p>
          This agreement between you and Sandhya Publications explains how you
          can use our Web sites and buy books listed on our Web sites. Use of
          Sandhya Publications Web site is governed by this agreement. By
          registering as a Sandhya Publications customer, you acknowledge and
          signify that you have read, understood, and agreed to be bound by this
          agreement.
        </p>

        <p>
          <strong>1. CHANGES TO THESE TERMS AND CONDITIONS</strong>
        </p>

        <p>
          Sandhya Publications may, in its discretion, change, supplement, or
          amend these Terms and Conditions as they relate to your future use of
          the Website from time to time, for any reason, and without any prior
          notice or liability to you or any other person, by posting the
          modified Terms and Conditions on the Websites. You may not change,
          supplement, or amend these Terms and Conditions in any manner. Each
          time you use the Website, you acknowledge and signify that you have
          read, understood, and agreed to be bound by these Terms and Conditions
          as they then read.
        </p>

        <p>
          <strong>2. PERMISSION TO USE THE WEBSITES</strong>
        </p>

        <p>
          The Websites may be accessed and used only by individuals who are able
          to enter into legally binding and enforceable contracts. Users of the
          Website must comply with all applicable laws. The Website may not be
          used by persons in jurisdictions where access to or use of the Website
          or any part of them may be illegal or prohibited.
        </p>

        <p>
          The Website is made available to you for your lawful, personal,
          non-commercial use only. You may not reproduce, copy, duplicate, sell,
          or resell any part of the Website or access to the Website.
        </p>

        <p>
          Sandhya Publications will not under any circumstances be liable to you
          or any other person for any loss of use, loss of production, loss of
          income or profits (anticipated or otherwise), loss of markets,
          economic loss, special, indirect or consequential loss or damage or
          punitive damages, whether in contract, tort or under any other theory
          of law or equity, arising from, connected with, or relating to the use
          of the Website by you or any other person.
        </p>

        <p>
          <strong>3. COMMUNICATIONS</strong>
        </p>

        <p>
          Communications sent to Sandhya Publications by means of the Website or
          e-mail are not considered delivered or effective unless and until they
          are actually received and processed by Sandhya Publications
          responsible representative.
        </p>

        <p>
          <strong>4. WEBSITE CONTENT</strong>
        </p>

        <p>
          Sandhya Publications is not obliged to monitor, screen, or edit the
          use of the Website, including postings of materials to the Website,
          although it reserves the right to do so in its discretion. Sandhya
          Publications will respond as it considers appropriate, in its
          discretion, if it becomes aware of any inappropriate uses of the
          Websites, including without limitation uses that constitute copyright
          infringement. You may report any claims or concerns (including
          copyright infringement claims) regarding Website or their content by
          sending an e-mail.
        </p>

        <p>
          <strong>5. OWNERSHIP OF THE WEBSITES</strong>
        </p>

        <p>
          The Websites and their content (including all information in text,
          graphical, video and audio forms, images, icons, software, design,
          applications and other elements available on or through the Websites)
          are the property of Sandhya Publications and others, and are protected
          by international copyright and other laws. Your use of the Website
          does not transfer to you any ownership or other rights in the Websites
          or their content.
        </p>

        <p>
          <strong>6. OTHER SITES</strong>
        </p>

        <p>
          The Website may include advertisements for, and links to, other
          websites or resources and businesses operated by other persons,
          including booksellers (“Other Sites”). Other Sites are independent
          from Sandhya Publications, and Sandhya Publications has no
          responsibility or liability for or control over Other Sites, their
          business, goods, services, or content. Links to Other Sites are
          provided solely for your convenience. Sandhya Publications does not
          sponsor or endorse any Other Sites or their content or the goods or
          services available through those Other Sites.
        </p>

        <p>
          <strong>
            7. LINKING, FRAMING, MIRRORING, SCRAPING, DATA-MINING OR POSTINGS
          </strong>
        </p>

        <p>
          Links to the Website without the express written permission of Sandhya
          Publications are strictly prohibited. The framing, mirroring, scraping
          or data-mining of the Website or any of their content in any form and
          by any method are strictly prohibited.
        </p>

        <p>
          <strong>8. PERSONAL INFORMATION PRIVACY</strong>
        </p>

        <p>
          Sandhya Publications collects, uses, and discloses your personal
          information in accordance with its Privacy Policy. Sandhya
          Publications may change its Privacy Policy from time to time in its
          sole discretion. Each time you use the Website, you consent to
          collection, use and disclosure of your personal information in
          accordance with its Privacy Policy as it then reads without any
          further notice or any liability to you or any other person.
        </p>

        <p>
          <strong>9. LEGAL CLAIMS</strong>
        </p>

        <p>
          For all disputes between Sandhya Publications and you or any other
          person arising from, connected with or relating to the Web site, this
          Agreement, transactions facilitated or conducted through the Web site,
          Items ordered or purchased through the Web site, dealings between You
          and Sandhya Publications, or any related matters or any legal
          relationship associated therewith or derived there from (“Disputes”),
          the relevant parties will attempt to find a reasonable solution least
          onerous to the parties. If a Dispute cannot be resolved by the
          parties, then the Dispute must be resolved before the Courts of India,
          and you hereby irrevocably submit and at torn to the original and
          exclusive jurisdiction of those courts in respect of all Disputes.
        </p>

        <p>
          <strong>10. END OF AGREEMENT</strong>
        </p>

        <p>
          Sandhya Publications may terminate this Agreement at any time with
          immediate effect and without any prior notice. Sandhya Publications
          may at any time and in its discretion immediately suspend or terminate
          (in whole or in part) your permission to use the Web site without any
          notice or liability to you or any other person.
        </p>

        <p>
          If this agreement or your permission to use the Web site is terminated
          by Sandhya Publications for any reason, this agreement will
          nevertheless continue to apply and be binding upon You in respect of
          Your prior use of the Web site and prior purchases of Items through
          the Web sites and anything connected with, relating to or arising from
          those matters.
        </p>

        <p>
          <strong>
            11. Changes to web sites, policies and procedures, and this
            agreement
          </strong>
        </p>

        <p>
          Sandhya Publications may, in its discretion, change, suspend or
          terminate, temporarily or permanently, the Web site or any part of
          them at any time, for any reason, without any notice or liability to
          You or any other person.
        </p>

        <p>
          Any rights not expressly granted in these Terms and Conditions are
          reserved to Sandhya Publications. These Terms and Conditions are
          subject to change without notice.
        </p>

        <p>
          Thank you for choosing Sandhya Publications for purchasing those much
          sought after books!
        </p>
      </div>
    </div>
  );
};

export default TermsConditions;
