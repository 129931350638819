import React, { lazy, Component, useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getProducts } from "../../redux/actions/productActions";
import { getCategories } from "../../redux/actions/categoriesActions";
import { Link, useHistory, useParams } from "react-router-dom";
import { data } from "../../data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTh, faBars } from "@fortawesome/free-solid-svg-icons";
import { SERVER_BASE_URL } from "../../config/config";
import { getSingleAuthor } from "../../redux/actions/authorActions";
import AppPagination from "../../helpers/appPagination";
const Paging = lazy(() => import("../../components/Paging"));
const Breadcrumb = lazy(() => import("../../components/Breadcrumb"));
const FilterCategory = lazy(() => import("../../components/filter/Category"));
const FilterPrice = lazy(() => import("../../components/filter/Price"));
const FilterSize = lazy(() => import("../../components/filter/Size"));
const FilterStar = lazy(() => import("../../components/filter/Star"));
const FilterColor = lazy(() => import("../../components/filter/Color"));
const FilterTag = lazy(() => import("../../components/filter/Tag"));
const FilterClear = lazy(() => import("../../components/filter/Clear"));
const CardServices = lazy(() => import("../../components/card/CardServices"));
const CardProductGrid = lazy(() =>
  import("../../components/card/CardProductGrid")
);
const CardProductList = lazy(() =>
  import("../../components/card/CardProductList")
);

const SingleAuthor = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const [view, setView] = useState("grid");

  const products = useSelector((state) => state?.product?.products?.payload);

  const allCategories = useSelector(
    (state) => state?.allCategories?.categories?.payload?.results
  );

  const singleAuthor = useSelector(
    (state) => state?.singleAuthor?.author?.payload
  );

  const authorBooks = singleAuthor?.books;

  console.log(singleAuthor);

  useEffect(() => {
    Promise.all([
      (async () => {
        dispatch(getSingleAuthor(id, page));
      })(),
    ]).then((values) => {
      console.log(values);
    });
  }, [page]);

  // useEffect(() => getAllProducts(), []);
  // useEffect(() => getAllCategories(), []);
  // state = {
  //   currentProducts: [],
  //   currentPage: null,
  //   totalPages: null,
  //   totalItems: 0,
  //   view: "list",
  // };

  // UNSAFE_componentWillMount() {
  //   const totalItems = this.getProducts().length;
  //   this.setState({ totalItems });
  // }

  // onPageChanged = (page) => {
  //   let products = this.getProducts();
  //   const { currentPage, totalPages, pageLimit } = page;
  //   const offset = (currentPage - 1) * pageLimit;
  //   const currentProducts = products.slice(offset, offset + pageLimit);
  //   this.setState({ currentPage, currentProducts, totalPages });
  // };

  const onChangeView = (e) => {
    if (e === "grid") {
      setView("grid");
    } else {
      setView("list");
    }
  };

  // const getProducts = () => {
  //   let products = data.products;
  //   products = products.concat(products);
  //   products = products.concat(products);
  //   products = products.concat(products);
  //   products = products.concat(products);
  //   products = products.concat(products);
  //   return products;
  // };

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {`${singleAuthor?.author?.name}  | ${singleAuthor?.author?.en}  |
            சந்தியா பதிப்பகம் |
            Sandhyapathippagam |
            Sandhyapublications`}
        </title>
        <link
          rel="canonical"
          href={`https://www.sandhyapublications.com/author/${id}`}
        />
      </Helmet>
      <div
        className="p-5 bg-primary bs-cover"
        style={
          {
            // backgroundImage: "url(../../images/banner/50-Banner.webp)",
          }
        }
      >
        <div className="container text-center">
          <span className="display-5 px-3 text-light">
            {singleAuthor?.author?.name} நூல்கள்
          </span>
        </div>
      </div>
      {/* <Breadcrumb /> */}
      <div className="container-fluid mt-3 mb-3">
        <div className="row">
          <div className="col-md-12">
            <hr />
            <div className="row g-3">
              {authorBooks &&
                authorBooks?.results?.map((authorBook, idx) => {
                  return (
                    <div key={idx} className="col-md-4 mb-5">
                      <CardProductGrid data={authorBook} type={"authorBook"} />
                    </div>
                  );
                })}
            </div>
            <hr />
            <AppPagination setPage={setPage} {...authorBooks} />
            {/* <Paging
                totalRecords={this.state.totalItems}
                pageLimit={9}
                pageNeighbours={3}
                onPageChanged={this.onPageChanged}
                sizing=""
                alignment="justify-content-center"
              /> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SingleAuthor;
