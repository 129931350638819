import React, { useState, Component, lazy, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { data } from "../../data";
import { getWishList } from "../../redux/actions/wishlistActions";
import { Link, useHistory } from "react-router-dom";
import { getMyOrders } from "../../redux/actions/ordersActions";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
const CardProductList2 = lazy(() =>
  import("../../components/card/CardProductList2")
);

const MyOrders = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authTokens = useSelector((state) => state?.userLogin?.user?.tokens);
  const myOrders = useSelector((state) => state?.getOrders?.orders?.payload);

  if (!authTokens) {
    history.push("/");
  }
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (authTokens) {
      (async () => {
        dispatch(getMyOrders(authTokens?.access?.token));
      })();
    } else {
      toast.error("Login/ Register to access Orders!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [authTokens]);

  return (
    <div className=" mb-3 bs-cover">
      <Helmet>
        <title>
          {`Orders |
            சந்தியா பதிப்பகம் |
            Sandhyapathippagam |
            Sandhyapublications`}
        </title>
        <link
          rel="canonical"
          href={`https://www.sandhyapublications.com/account/my-orders`}
        />
      </Helmet>
      <div className="p-5 bg-primary bs-cover mb-5">
        <div className="container text-center">
          <span className="display-5 px-3 text-light">My Orders</span>
        </div>
      </div>
      <div
        style={{ width: "80%", marginLeft: "10%", padding: "50px" }}
        className="row"
      >
        <div className="container g-3 mb-5">
          <div className="col-md-12 row">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Payment ID</th>
                  <th scope="col">Items Purchased</th>
                  <th scope="col">Purchased On</th>
                  <th scope="col">Total Amount</th>
                  <th scope="col">Payment Status</th>
                  <th scope="col">Delivery Status</th>
                </tr>
              </thead>
              <tbody>
                {!myOrders?.results
                  ? "No Orders has been placed!"
                  : myOrders?.results?.map((d, i) => (
                      <tr>
                        <th scope="row">{i + 1}</th>
                        <td style={{ fontSize: "12px" }}>
                          {d?.paymentId ?? "N/A"}
                        </td>
                        <td>
                          {d?.items?.map((item, j) => (
                            <tr>
                              <span style={{ fontSize: "12px" }}>
                                {j + 1}
                                {") "}
                                {item?.onModel === "Combo" ? (
                                  item?.item?.name
                                ) : (
                                  <Link to={`/book/${item?.item?.id}`}>
                                    {item?.item?.title}
                                  </Link>
                                )}{" "}
                                <br></br>
                              </span>
                            </tr>
                          ))}
                        </td>{" "}
                        <td style={{ fontSize: "12px" }}>
                          {d?.purchasedOn ?? "N/A"}
                        </td>{" "}
                        <td style={{ fontSize: "12px" }}>
                          ₹ {d?.totalAmount ?? "N/A"}
                        </td>
                        <td style={{ fontSize: "12px" }}>
                          {d?.paymentStatus ?? "N/A"}
                        </td>{" "}
                        <td style={{ fontSize: "12px" }}>
                          {d?.status ?? "N/A"}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyOrders;
