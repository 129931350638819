import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as IconTelephone } from "bootstrap-icons/icons/telephone.svg";
import { ReactComponent as IconEnvelope } from "bootstrap-icons/icons/envelope.svg";
import { ReactComponent as IconBriefcase } from "bootstrap-icons/icons/briefcase.svg";
import { ReactComponent as IconBadgeAd } from "bootstrap-icons/icons/badge-ad.svg";
import { ReactComponent as IconGift } from "bootstrap-icons/icons/gift.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebookF,
  faInstagram,
  faYoutube,
  faApple,
  faWindows,
  faAndroid,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <React.Fragment>
      <footer>
        <div className="container-fluid footer-bg text-black">
          <div className="row ">
            <div className="footer-logo col-md-5 py-3 text-center">
              <Link to="/">
                <img
                  width={"300px"}
                  height={"70px"}
                  alt="logo"
                  src="../../images/logo-sandhiya.png"
                />
              </Link>
              <p>
                No. 77, 53rd Street, Ashok Nagar, <br></br> Chennai 600 083.
                <br></br>Phone Number: 0442896979
                <br></br>WhatsApp: 9444715315 / 9840952919
              </p>
            </div>
            <div className="col-md-3 py-3">
              <div className="h4">Information</div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item text-white">
                  <Link
                    to="/about"
                    className="text-decoration-none text-dark stretched-link"
                  >
                    About Us
                  </Link>
                </li>
                <li className="list-group-item text-white">
                  <Link
                    to="/delivery-shipping-policy"
                    className="text-decoration-none text-dark stretched-link"
                  >
                    Delivery & Shopping
                  </Link>
                </li>
                <li className="list-group-item text-white">
                  <Link
                    to="/privacy-policy"
                    className="text-decoration-none text-dark stretched-link"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li className="list-group-item text-white">
                  <Link
                    to="/terms-and-conditions"
                    className="text-decoration-none text-dark stretched-link"
                  >
                    Terms & Conditions
                  </Link>
                </li>
                <li className="list-group-item text-white">
                  <Link
                    to="/refund-and-cancellations"
                    className="text-decoration-none text-dark stretched-link"
                  >
                    Refund & Cancellations
                  </Link>
                </li>
                <li className="list-group-item text-white">
                  <Link
                    to="/contact-us"
                    className="text-decoration-none text-dark stretched-link"
                  >
                    Contact Us
                  </Link>
                </li>
                <li className="list-group-item text-white">
                  <Link
                    to="/catalogue"
                    className="text-decoration-none text-dark stretched-link"
                  >
                    Catalogue
                  </Link>
                </li>
                <li className="list-group-item text-white">
                  <Link
                    to="/youtube-links"
                    className="text-decoration-none text-dark stretched-link"
                  >
                    காணொளி
                  </Link>
                </li>
              </ul>
            </div>
            {/* <div className="col-md-2 py-3">
              <div className="h4">Customer Services</div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item text-white border-light">
                  <Link
                    to="/"
                    className="text-decoration-none text-dark stretched-link"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div> */}
            <div className="col-md-3 py-3">
              <div className="h4">My Account</div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item text-white border-light">
                  <Link
                    to="/account/my-orders"
                    className="text-decoration-none text-dark stretched-link"
                  >
                    Order History
                  </Link>
                </li>
                <li className="list-group-item text-white border-light">
                  <Link
                    to="/account/wishlist"
                    className="text-decoration-none text-dark stretched-link"
                  >
                    Wishlist
                  </Link>
                </li>
                {/* <li className="list-group-item text-white border-light">
                  <Link
                    to="/contact-us"
                    className="text-decoration-none text-dark stretched-link"
                  >
                    Contact Us
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="container-fluid bg-secondary text-white text-center">
          <div className="row">
            <div className="col-md-2 py-2">
              <Link to="/" className="text-white text-decoration-none">
                <IconBriefcase className="text-warning" /> Partner with us
              </Link>
            </div>
            <div className="col-md-2 py-2">
              <Link to="/" className="text-white text-decoration-none">
                <IconBadgeAd className="text-info" /> Advertise
              </Link>
            </div>
            <div className="col-md-2 py-2">
              <Link to="/" className="text-white text-decoration-none">
                <IconGift className="text-dark" /> Gift
              </Link>
            </div>
            <div className="col-md-3 py-2">
              © 2009-{new Date().getFullYear()} React-E-Commerce.com
            </div>
            <div className="col-md-3 py-2 bg-white">
              <img
                src="../../images/payment/american_express.webp"
                width="32"
                alt="American Express"
                className="mr-2"
              />
              <img
                src="../../images/payment/maestro.webp"
                width="32"
                alt="Maestro"
                className="mr-2"
              />
              <img
                src="../../images/payment/netbanking.webp"
                width="32"
                alt="Net Banking"
                className="mr-2"
              />
              <img
                src="../../images/payment/paypal.webp"
                width="32"
                alt="Paypal"
                className="mr-2"
              />
              <img
                src="../../images/payment/rupay.webp"
                width="32"
                alt="Rupay"
                className="mr-2"
              />
              <img
                src="../../images/payment/upi.webp"
                width="32"
                alt="UPI"
                className="mr-2"
              />
              <img
                src="../../images/payment/visa.webp"
                width="32"
                alt="Visa"
                className="mr-2"
              />
            </div>
          </div>
        </div> */}
        <div style={{ color: "black", textAlign: "center" }}>
          Copyright © 2022, Sandhya Publications
        </div>
      </footer>
    </React.Fragment>
  );
};
export default Footer;
