import React, { useState, Component, lazy, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { data } from "../../data";
import { getWishList } from "../../redux/actions/wishlistActions";
import { useHistory } from "react-router-dom";
const CardProductList2 = lazy(() =>
  import("../../components/card/CardProductList2")
);

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className=" mb-3 bs-cover">
      <div className="p-5 bg-primary bs-cover mb-5">
        <div className="container text-center">
          <span className="display-5 px-3 text-light">Privacy Policy</span>
        </div>
      </div>
      <div className="container g-3 mb-5">
        <p>
          We respect your privacy and Sandhya Publications does not rent, sell,
          or share personal information about you with third parties, except
          when specifically informed to you at the time of collection of the
          information .
        </p>

        <p>
          <strong>1. Information Collected by Us</strong>
        </p>

        <p>
          We collect personally identifiable information (email address,
          name,phone number, etc.) from you when you set up a free account with
          sandhyapublications.com. While you can browse some sections of our
          site without being a registered member, certain activities (such as
          placing an order) do require registration.
        </p>

        <p>
          We do use your contact information to send you offers based on your
          previous orders and your interests. We will not share this information
          with any other company or to a third party organization.
        </p>

        <p>
          <strong>2. Security Measures</strong>
        </p>

        <p>
          Our site has stringent security measures in place to protect the loss,
          misuse, and alteration of the information under our control. Whenever
          you change or access your account information, we offer the use of a
          secure server. Once your information is in our possession we adhere to
          strict security guidelines, protecting it against unauthorized access.
        </p>

        <p>
          <strong>
            3. Is it safe to use my credti/debit or give my account details
            here?
          </strong>
        </p>

        <p>
          We do not collect or store your bank/card information, you will be
          redirected to a trusted payment gateway for processing these
          information. All your personal information like passwords, credit card
          number, transaction history are encrypted so that they cannot be read
          as it travels over the Internet.
        </p>

        <p>
          Further, at sandhyapublications.com, we insist on you entering the CVV
          (Credit Verification Value) number on your credit card. This is a 3
          digit number which follows your credit card number and is given at the
          back of the card. In other words, even if someone has access to your
          credit card number alone (this is possible since you leave behind this
          number every time you transact with your card anywhere, including
          physical stores / outlets), they cannot use it to transact at
          sandhyapublications.com without the CVV number.
        </p>

        <p>
          <strong>4. Links to Other Sites</strong>
        </p>

        <p>
          We don’t post any advertisement or redirections to any other sites.
          &nbsp;sandhyapublications.com is not responsible for the privacy
          practices or the content of those linked websites . We are linking to
          other sites only for easy account integration, as it’s easy for you to
          manage the accounts. However apart from this, we do not link to other
          sites.
        </p>

        <p>
          <strong>4. Questions?</strong>
        </p>

        <p>
          Please feel to post your Questions regarding this statement the
          following address: info@sandhyapublications.com,
          sandhyapublications@yahoo.com
        </p>

        <p>
          Sandhya Publications may update this policy from time to time. We may
          notify you about significant changes in the way we treat personal
          information by sending a notice to the primary email address specified
          in your Sandhya Publications account or by placing a notice on our
          site for a reasonable period of time.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
