import React, { useState, Component, lazy, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { data } from "../../data";
import { getWishList } from "../../redux/actions/wishlistActions";
import { useHistory } from "react-router-dom";
const CardProductList2 = lazy(() =>
  import("../../components/card/CardProductList2")
);

const About = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className=" mb-3 bs-cover">
      <div className="p-5 bg-primary bs-cover mb-5">
        <div className="container text-center">
          <span className="display-5 px-3 text-light">About Us</span>
        </div>
      </div>
      <div className="container g-3 mb-5">
        Sandhya Publications is a leading publisher in Tamil language. We have
        brought out more than 1000 titles in the last 22 years right from the
        time of our inception in the year 2000. We are known for tamil
        translations of world classics and the works of modern masters of Tamil
        language in both fiction and non fiction. Many of our titles have
        received state awards and awards instituted by prestigious literary
        forms. We are participating in all the book fairs conducted in Tamil
        Nadu, New Delhi & Bangalore.{" "}
      </div>
    </div>
  );
};

export default About;
