import React, { useState, Component, lazy, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { data } from "../../data";
import { getWishList } from "../../redux/actions/wishlistActions";
import { useHistory } from "react-router-dom";
const CardProductList2 = lazy(() =>
  import("../../components/card/CardProductList2")
);

const DeliveryPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className=" mb-3 bs-cover">
      <div className="p-5 bg-primary bs-cover mb-5">
        <div className="container text-center">
          <span className="display-5 px-3 text-light">
            Delivery & Shipping Policy
          </span>
        </div>
      </div>
      <div className="container g-3 mb-5">
        <p>
          <strong>
            <span>Are there any shipping charges?</span>
          </strong>
        </p>

        <p>
          Sandhya Publications provides free shipping on all books if your total
          order amount is more than Rs. 500. Otherwise add Rs. 50 for shipping
          charges. This is applicable only in India.
        </p>

        <p>
          For rest of India, other countries the shipping charge is caluculated
          based on weight.
        </p>

        <p>
          <strong>What is the estimated delivery time?</strong>
        </p>

        <p>
          The delivery time for each book is mentioned against the specific
          book. Generally, the books are available in 3-5 business days.
          However, since the availability of each book varies from time to time,
          please check the exact timelines on the book page itself before
          ordering.Also, we normally ship all books through reputed couriers.
          For other areas which are not serviced by the courier, the books are
          sent by Registered Post through the Indian Postal Service which may
          take additional 5-7 business days depending on the location.
        </p>

        <p>
          Business days exclude public holidays and Sundays. Also note that this
          doesn't apply to bulk orders.
        </p>

        <p>
          <strong>
            How will I come to know about order / delivery details etc.?
          </strong>
        </p>

        <p>
          With every order, you will receive an email containing the details of
          the order placed by you. We will notify you and keep you updated once
          the order is placed, processed, shipped, completed via e-mail. Please
          feel free to contact us in the below mentioned numbers for any
          clarifications.
        </p>

        <p>
          <strong>
            What about other taxes and levies like sales tax etc.?
          </strong>
        </p>

        <p>
          What you see is what you pay. Our prices are all inclusive. No hidden
          charges or additional taxes will be added.
        </p>

        <p>
          <strong>Does Sandhya Publications deliver internationally?</strong>
        </p>

        <p>Yes we ship to all countries.</p>

        <p>
          But you cannot order books online. You should send your orders/queries
          to <span>sandhyapublications@yahoo.com</span>. Or contact our office
          Phone No. +91 44 24896979. Our executive will calculate the amount and
          send it you.
        </p>

        <p>
          We will ship your orders via TNT, FedEx, Aramex and India post.&nbsp;
        </p>

        <p>
          <strong>Where are My Recent Orders?</strong>
        </p>

        <p>
          The most current summary for all your orders is always available in
          "My Account" page. You can view all your history of orders in My
          Account.
        </p>

        <p>
          <strong>
            Is there any additional amount needed to be paid at the time of
            delivery?
          </strong>
        </p>

        <p>
          No. There is no need to pay any additional amount at the time of
          delivery.
        </p>

        <p>
          <strong>
            What do I do in case I receive a defective/damaged / wrong book from
            Sandhya Publications ?
          </strong>
        </p>

        <p>
          We at Sandhya Publications always have you in mind, so we strive to
          make sure that our products reach you in their original condition.
          Still if an book ordered by you on is delivered to you in a damaged /
          defective condition or a wrong book is inadvertently delivered to you,
          please email us at sandhyapublications@yahoo.com within 48 hrs for a
          replacement . We will send you a new book at no extra cost.&nbsp;
        </p>

        <p>We will not refund the amount at any cost.</p>

        <p>
          <strong>
            How can I cancel a product ordered from Sandhya Publications?
          </strong>
        </p>

        <p>You cannot cancel the order at any cost.</p>

        <p>
          <strong>
            Does Sandhya Publications provide any warranty/guarantee for the
            products/things purchased through external links?
          </strong>
        </p>

        <p>
          Your correspondence or business dealings with, or participation in
          promotions of, advertisers found on or through the Sites, including
          payment and delivery of related goods or services, and any other
          terms, conditions, warranties or representations associated with such
          dealings, are solely between you and such advertiser. Sandhya
          Publications shall not be responsible or liable for any loss or damage
          of any sort incurred as the result of any such dealings or as the
          result of the presence of such advertisers on the Sites.
        </p>

        <p>
          <strong>Customer Support</strong>
        </p>

        <p>
          <strong>email: </strong>info@sandhyapublications.com,&nbsp;
          <span>sandhyapublications@yahoo.com</span>
        </p>

        <p>
          <strong>Telephone numbers:</strong> +91 44 24896979, +91 98411 91397
        </p>
      </div>
    </div>
  );
};

export default DeliveryPolicy;
