import { ActionTypes } from "../constants/action-types";
import axios from "axios";
import { toast } from "react-toastify";
import { SERVER_BASE_URL } from "../../config/config";

export const addToCart =
  (product, type, token) => async (dispatch, getState) => {
    // try {
    if (!token) {
      const currCartItems = getState()?.getCart?.cart;
      console.log("productId", currCartItems, product);
      return dispatch({
        type: ActionTypes.ADD_TO_CART,
        payload: currCartItems
          ? [...currCartItems.items, { item: product.item, onModel: type }]
          : [{ item: product.item, onModel: type }],
      });
    }
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const response = await axios.post(
      `${SERVER_BASE_URL}/v1/app/add-to-cart`,
      { item: product.item.id, type },
      config
    );
    dispatch({
      type: ActionTypes.GET_CART,
      payload: response.data.payload,
    });
    // } catch (e) {
    //   toast.error("Signup/Login to Add to Cart!");
    // }
  };

export const addToCartFromCard =
  (product, type, token) => async (dispatch, getState) => {
    // try {
    if (!token) {
      const currCartItems = getState()?.getCart?.cart;
      console.log("productId", currCartItems);
      return dispatch({
        type: ActionTypes.ADD_TO_CART,
        payload: currCartItems
          ? [...currCartItems.items, { item: product, onModel: type }]
          : [{ item: product, onModel: type }],
      });
    }
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const response = await axios.post(
      `${SERVER_BASE_URL}/v1/app/add-to-cart`,
      { item: product.id, type },
      config
    );
    dispatch({
      type: ActionTypes.GET_CART,
      payload: response.data.payload,
    });
    // } catch (e) {
    //   toast.error("Signup/Login to Add to Cart!");
    // }
  };

export const getCart = (token) => async (dispatch) => {
  try {
    const response = await axios.get(`${SERVER_BASE_URL}/v1/app/cart`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: ActionTypes.GET_CART,
      payload: response.data.payload,
    });
  } catch (e) {
    toast.error(e);
  }
};

export const removeItemCart = (token, id) => async (dispatch, getState) => {
  try {
    if (!token) {
      const currCartItems = getState()?.getCart?.cart;
      console.log("currCartItemscurrCartItems", currCartItems, id);
      const items = currCartItems?.items?.filter((item) => item.item.id !== id);
      return dispatch({
        type: ActionTypes.ADD_TO_CART,
        payload: [...items],
      });
    }

    const response = await axios.post(
      `${SERVER_BASE_URL}/v1/app/remove-from-cart`,
      { item: id },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    dispatch({
      type: ActionTypes.REMOVE_ITEM_CART,
      payload: response.data.payload,
    });
  } catch (e) {
    toast.error(e);
  }
};

export const removeSingleItemCart =
  (token, id, item, type) => async (dispatch, getState) => {
    try {
      if (!token) {
        const currCartItems = getState()?.getCart?.cart;
        const index = currCartItems?.items?.findIndex(
          (item) => item.item.id === id
        );
        currCartItems.items.splice(index, 1);
        return dispatch({
          type: ActionTypes.ADD_TO_CART,
          payload: [...currCartItems.items],
        });
      }
      const response = await axios.post(
        `${SERVER_BASE_URL}/v1/app/remove-one-from-cart`,
        { ...item, item: item.item.id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch({
        type: ActionTypes.REMOVE_SINGLE_ITEM_CART,
        payload: response.data.payload,
      });
    } catch (e) {
      toast.error(e);
    }
  };

export const clearCart = () => async (dispatch) => {
  dispatch({
    type: ActionTypes.CLEAR_CART,
  });
};
