import React, { useEffect, useState } from "react";
import { ReactComponent as IconSearch } from "bootstrap-icons/icons/search.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getProducts } from "../redux/actions/productActions";

const Search = ({ onFocus, onBlur, onChange }) => {
  const dispatch = useDispatch();

  return (
    <form action="#" className="search">
      <div className="input-group">
        <input
          id="search"
          name="search"
          type="text"
          className="form-control"
          placeholder="Search"
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          autoComplete={"off"}
          required
        />
        <label className="visually-hidden" htmlFor="search"></label>
        {/* <button
          className="btn btn-danger text-white"
          type="submit"
          aria-label="Search"
        >
          <IconSearch />
        </button> */}
      </div>
    </form>
  );
};
export default Search;
