export const ActionTypes = {
  //AUTH
  SET_USER: "SET_USER",
  ADD_USER: "ADD_USER",
  LOGOUT_USER: "LOGOUT_USER",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  SAVE_ADDRESS: "SAVE_ADDRESS",
  //PRODUCTS

  GET_PRODUCTS: "GET_PRODUCTS",
  SET_NEW_ARRIVALS: "SET_NEW_ARRIVALS",
  SET_OLD_AUTHORS_CHOICE: "SET_OLD_AUTHORS_CHOICE",
  SET_TRENDING_NOW: "SET_TRENDING_NOW",
  SET_DISCOUNTED_BOOKS: "SET_DISCOUNTED_BOOKS",
  SET_BEST_SELLERS: "SET_BEST_SELLERS",
  SELECTED_PRODUCT: "SELECTED_PRODUCT",
  REMOVE_SELECTED_PRODUCT: "REMOVE_SELECTED_PRODUCT",
  GET_CATEGORIES: "GET_CATEGORIES",
  GET_CATEGORY: "GET_CATEGORY",
  SET_REVIEW: "SET_REVIEW",

  GET_COMBOS: "GET_COMBOS",

  SET_AUTHOR: "SET_AUTHOR",
  GET_AUTHORS: "GET_AUTHORS",
  GET_LIST_AUTHORS: "GET_LIST_AUTHORS",
  GET_SINGLE_AUTHOR: "GET_SINGLE_AUTHOR",

  SET_TRANSLATOR: "SET_TRANSLATOR",
  GET_TRANSLATORS: "GET_TRANSLATORS",
  GET_SINGLE_TRANSLATOR: "GET_SINGLE_TRANSLATOR",

  //CART
  GET_CART: "GET_CART",
  ADD_TO_CART: "ADD_TO_CART",
  REMOVE_ITEM_CART: "REMOVE_ITEM_CART",
  REMOVE_SINGLE_ITEM_CART: "REMOVE_SINGLE_ITEM_CART",
  CLEAR_CART: "CLEAR_CART",

  //WISHLIST
  ADD_TO_WISHLIST: "ADD_TO_WISHLIST",
  GET_WISHLIST: "GET_WISHLIST",
  REMOVE_ITEM_WISHLIST: "REMOVE_ITEM_WISHLIST",

  SEARCH_PRODUCTS: "SEARCH_PRODUCTS",
  GET_BANNER: "GET_BANNER",
  GET_MY_ORDERS: "GET_MY_ORDERS",
};
