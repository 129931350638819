import { combineReducers } from "redux";
import { productReducer } from "./productReducer";
import { authorReducer } from "./authorReducer";
import { authReducer } from "./authReducer";
import { cartReducer } from "./cartReducer";
import { categoriesReducer } from "./categoriesReducer";
import { wishlistReducer } from "./wishlistReducer";
import { bannerReducer } from "./bannerReducer";
import { myOrdersReducer } from "./myOrdersReducer";
import { translatorReducer } from "./translatorReducer";

const reducers = combineReducers({
  product: productReducer,
  addWishlist: wishlistReducer,
  allWishlist: wishlistReducer,
  removeItemWishlist: wishlistReducer,
  allCategories: categoriesReducer,
  singleCategory: categoriesReducer,
  singleAuthor: authorReducer,
  allAuthors: authorReducer,
  singleTranslator: translatorReducer,
  allTranslators: translatorReducer,
  userLogin: authReducer,
  addToCart: cartReducer,
  getCart: cartReducer,
  removeItem: cartReducer,
  removeSingleItem: cartReducer,
  getBanner: bannerReducer,
  getOrders: myOrdersReducer,
});

export default reducers;
