import React, { useState, Component, lazy, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { data } from "../../data";
import { getWishList } from "../../redux/actions/wishlistActions";
import { Link, useHistory } from "react-router-dom";
const CardProductList2 = lazy(() =>
  import("../../components/card/CardProductList2")
);

const SuccessPage = () => {
  const token = useSelector(
    (state) => state?.userLogin?.user?.tokens?.access?.token
  );
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div style={{ marginLeft: "40%" }} className="container g-3 mb-5 mt-5">
      <div class="successNote successcard">
        <div
          style={{
            bordeRadius: "200px",
            height: "200px",
            width: "100%",
            background: " #F8FAF5",
          }}
        >
          <i class="checkmark">✓</i>
        </div>
        <h1>Success</h1>
        <p>
          We received your purchase request;
          <br /> we'll be in touch shortly!
        </p>

        {token ? (
          <>
            <Link to={"/account/my-orders"}>
              <button style={{ border: 0, background: "pink" }}>
                My Orders
              </button>
            </Link>
            <Link to={"/"}>
              <button style={{ border: 0, background: "pink" }}>Home</button>
            </Link>
          </>
        ) : (
          <Link to={"/"}>
            <button style={{ border: 0, background: "pink" }}>Home</button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default SuccessPage;
